import React, { useState, useRef, useCallback, useEffect } from "react";
import { LoadScript, GoogleMap, Polygon, DrawingManager, Autocomplete } from "@react-google-maps/api";

const libraries = ["places", "drawing"];

const mapStyles = {
  width: '100%',
  height: '100%'
};

const MapContainer = ({ getCords }) => {
  const [cords, setCords] = useState('');
  const [polygonData, setPolygonData] = useState([]);
  const [currentPosition, setCurrentPosition] = useState({
      lat: 44.507399,
      lng: -99.1518101
  });
  const [autocomplete, setAutocomplete] = useState();

  const onPolygonComplete = (data) => {
    console.log(data);
    console.log(data.getPath());
    const path = data.getPath();

    var pathArray = [];
    let polygonArray = [];

    for (var i = 0; i < path.length; i++) {
      pathArray = [path.getAt(i).lat(), path.getAt(i).lng()];
      polygonArray.push(pathArray);
    }

    setPolygonData(polygonArray);
    console.log("polygon complete", polygonArray);

    let area = window.google.maps.geometry.spherical.computeArea(data.getPath());
    getCords(area);
  };

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
}

  const resetPolygon = e => {
    e.preventDefault();
    setPolygonData([]);
  };

  useEffect(() => {
    if (autocomplete !== undefined) {
      if (autocomplete.geometry !== undefined) {
        setCurrentPosition({
          lat: autocomplete.geometry.location.lat(),
          lng: autocomplete.geometry.location.lng()
        });
      }
    }
}, [autocomplete]);

  //Function triggered when place is changed
  const onPlaceChanged = () => {
    if (autocomplete !== null) {
    setAutocomplete(autocomplete.getPlace());
    } else {
    console.log("Autocomplete is not loaded yet!");
    }
  }

    return (
      <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyCdzNwm5lcIGSeB-kaDlhGHAOd3LWlkR4g"
        language="en"
        region="us"
        libraries={libraries}
      >
        <GoogleMap
            mapContainerClassName="App-map"
            center={currentPosition}
            options={{
                disableDefaultUI: true,
                zoom: 19,
                tilt: 0,
                mapTypeId: 'satellite'
            }}
            on
        >
          <DrawingManager
            options={{
              drawingControlOptions: {
                drawingModes: ["polygon"]
              },
              polygonOptions: {
                fillColor: 'red',
                strokeColor: 'red',
                strokeWeight: 4,
                fillOpacity: 0.3,
                editable: true
              }
            }} 
            onPolygonComplete={(polygon) => onPolygonComplete(polygon)} 
          />
          <Autocomplete onLoad={onLoad} onPlaceChanged={() => onPlaceChanged()}>
            <input
                type="text"
                placeholder="Vänligen ange adress"
                style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `344px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px"
                }}
            />
          </Autocomplete>
        </GoogleMap>
      </LoadScript>
    );
}

export default MapContainer
