import React, { useState, useEffect } from "react"
import * as Icon from "react-feather"

import Header from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MapContainer from "../components/MapContainer"

const libraries = ["places", "drawing"];

const SolcellsKalkylator = () => {
    const [cords, setCords] = useState(false);
    const [activeStep, setActiveStep] = useState(4);

    const [input, setInput] = useState({
        roofSlope: 1
    });

    const changeInput = (name, value) => {
        const arr = { ...input };
        arr[name] = value;
        setInput(arr);
    }

    const nextStep = () => {
        if (activeStep !== 5) {
            setActiveStep(activeStep + 1);
        } else {
            // post form show summary logic here
        }
    }

    const getCords = (cords) => setCords(Math.floor(cords))

    return (
        <Layout>
            <Seo title="Solcellskalkylator" />
            <Header image={true} />
            
            <section className="calculate">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <ul className="list-inline">
                                <li className={activeStep === 1 ? "list-inline-item active" : activeStep > 1 ? "list-inline-item has-been" : "list-inline-item"}>Adress</li>
                                <li className={activeStep === 2 ? "list-inline-item active" : activeStep > 2 ? "list-inline-item has-been" : "list-inline-item"}>Antal solceller</li>
                                <li className={activeStep === 3 ? "list-inline-item active" : activeStep > 3 ? "list-inline-item has-been" : "list-inline-item"}>Taklutning</li>
                                <li className={activeStep === 4 ? "list-inline-item active" : activeStep > 4 ? "list-inline-item has-been" : "list-inline-item"}>Paneltyp</li>
                                <li className={activeStep === 5 ? "list-inline-item active" : activeStep > 5 ? "list-inline-item has-been" : "list-inline-item"}>Summering</li>
                                {/* <li className="list-inline-item gray">Årligbesparing elkostnad</li>
                                <li className="list-inline-item gray">Pris inkl. installation</li> */}
                            </ul>
                        </div>
                    </div>

                    {activeStep === 1 && (
                        <div className="row mt-3">
                            <div className="col-lg-7 text-center">
                                <div style={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <div className="text">
                                        <h2>Räkna potentialen på ditt tak</h2>
                                        <p>Skriv in din adress i fältet nedan för att mäta ditt tak. Tillsammans kan vi skapa ett mer hållbart Sverige.</p>
                                        <button type="button" onClick={() => nextStep()} className="btn btn-primary">Påbörja uträkning <Icon.ChevronRight /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeStep === 2 && (
                        <div className="row mt-3">
                            <div className="col-lg-5">
                                <div style={{ borderRadius: '10px', overflow: 'hidden', width: '100%', height: '420px', position: 'relative' }}>
                                    {cords && (
                                        <div className="information shadow" style={{
                                            position: 'absolute',
                                            bottom: '10px', left: '20px', right: '20px',
                                            background: '#fff', zIndex: 15000, padding: '15px 20px',
                                            fontSize: '14px', fontWeight: 600, borderRadius: '10px'
                                        }}>
                                            {`${cords} kvm ${Math.floor(cords/2.2)} paneler`}
                                        </div>
                                    )}
                                    <MapContainer getCords={getCords} />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div style={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <div className="text">
                                        <h2>Var vill du placera solcellerna?</h2>
                                        <p>Markera området där du vill placera solcellerna genom att klicka på kartan. När du har anslutit alla hörnen kan du se antal paneler och ångra om du ritat fel.</p>
                                        <button type="button" onClick={() => nextStep()} className="btn btn-primary">Vidare till taklutning <Icon.ChevronRight /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeStep === 3 && (
                        <div className="row mt-3">
                            <div className="col-lg-5">
                                <img 
                                    src={`/img/calculate/roofSlope${input.roofSlope}.jpeg`} 
                                    alt="" 
                                    style={{
                                        borderRadius: '10px',
                                        width: '100%'
                                    }}
                                />
                            </div>
                            <div className="col-lg-7">
                                <div style={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <div className="text">
                                        <h2>Hur mycket lutar ditt tak?</h2>
                                        <p>Detta hjälper oss att beräkna soleffekten.</p>

                                        <div className="options">
                                            <div className={input.roofSlope === 1 ? "option active" : "option"} onClick={() => changeInput('roofSlope', 1)}>Platt tak <span>0-15º</span></div>
                                            <div className={input.roofSlope === 2 ? "option active" : "option"} onClick={() => changeInput('roofSlope', 2)}>Vanligt tak <span>15-30º</span></div>
                                            <div className={input.roofSlope === 3 ? "option active" : "option"} onClick={() => changeInput('roofSlope', 3)}>Spetsigt tak <span>{'< 30º'}</span></div>
                                        </div>

                                        <button type="button" onClick={() => nextStep()} className="btn btn-primary">Vidare till paneltyp <Icon.ChevronRight /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeStep === 4 && (
                        <div className="row mt-3">
                            <div className="col-lg-5">
                                dwqdq
                            </div>
                            <div className="col-lg-7">
                                <div style={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <div className="text">
                                        <h2>Välj typ av solpanel</h2>
                                        <p>Välj bland våra alternativ för att hitta rätt lösning för dig. Pris och besparing ändras baserat på dina val. Se alla detaljer i nästa steg.</p>

                                        <div className="row options">
                                            <div className={input.roofSlope === 1 ? 
                                                "col-lg-3 text-center option active" : 
                                                "col-lg-3 text-center option"
                                            } onClick={() => changeInput('roofSlope', 1)}>Essential</div>
                                            <div className={input.roofSlope === 2 ? 
                                                "col-lg-3 text-center option active" : 
                                                "col-lg-3 text-center option"
                                            } onClick={() => changeInput('roofSlope', 2)}>Design</div>
                                            <div className={input.roofSlope === 3 ? 
                                                "col-lg-3 text-center option active" : 
                                                "col-lg-3 text-center option"
                                            } onClick={() => changeInput('roofSlope', 3)}>Pro</div>
                                            <div className={input.roofSlope === 4 ? 
                                                "col-lg-3 text-center option active" : 
                                                "col-lg-3 text-center option"
                                            } onClick={() => changeInput('roofSlope', 4)}>Power Roof</div>
                                        </div>

                                        <button type="button" onClick={() => nextStep()} className="btn btn-primary">Se summering <Icon.ChevronRight /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeStep === 5 && (
                        <div className="row mt-3">
                            <div className="col-lg-5">
                                <div className="row bg-primary bg-gradient text-white px-2 py-4 lh-base">
                                    <div className="row">
                                        <div className="col" style={{ fontWeight: 600 }}>Pris inkl. installation</div>
                                        <div className="col" style={{ fontWeight: 600, textAlign: 'right' }}>314 375 kr</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Avdrag för grön teknik inkl.</div>
                                        <div className="col" style={{ textAlign: 'right' }}>53 530 kr</div>
                                    </div>
                                    <div className="row"><div className="col"><hr /></div></div>
                                    <div className="row">
                                        <div className="col">Årligbesparing elkostnad</div>
                                        <div className="col" style={{ textAlign: 'right' }}>38 411 kr</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Återbetalningstid</div>
                                        <div className="col" style={{ textAlign: 'right' }}>7 år</div>
                                    </div>
                                    <div className="row"><div className="col"><hr /></div></div>
                                    <div className="row">
                                        <div className="col">Producerad energi per år</div>
                                        <div className="col" style={{ textAlign: 'right' }}>32552 kWh</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Mängd räddad olja (i badkar)</div>
                                        <div className="col" style={{ textAlign: 'right' }}>651</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div style={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <div className="text">
                                        <h2>Fyll i dina uppgifter</h2>
                                        <p>Lämna dina uppgifter så att en av våra solkonsulter kan kontakta dig för att kunna ge dig en mer detaljerad offert och förklara hur enkelt det är att installera solpaneler och börja producera din egen el.</p>

                                        <div className="row">
                                            <div className="col">
                                                <label style={{ fontWeight: 600, marginBottom: '5px' }}>Förnamn</label>
                                                <input type="text" name="" className="form-control" />
                                            </div>
                                            <div className="col">
                                                <label style={{ fontWeight: 600, marginBottom: '5px' }}>Efternamn</label>
                                                <input type="text" name="" className="form-control" />
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col">
                                                <label style={{ fontWeight: 600, marginBottom: '5px' }}>Adress</label>
                                                <input type="text" name="" className="form-control" />
                                            </div>
                                            <div className="col">
                                                <label style={{ fontWeight: 600, marginBottom: '5px' }}>Postnr. & ort</label>
                                                <input type="text" name="" className="form-control" />
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col">
                                                <label style={{ fontWeight: 600, marginBottom: '5px' }}>E-mail</label>
                                                <input type="text" name="" className="form-control" />
                                            </div>
                                            <div className="col">
                                                <label style={{ fontWeight: 600, marginBottom: '5px' }}>Telefon</label>
                                                <input type="text" name="" className="form-control" />
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col" style={{ fontSize: '14px', lineHeight: 1.4 }}>
                                                <input type="checkbox" name="" /> Jag accepterar att Svea Solar lagrar mina personuppgifter och integritetspolicy.
                                            </div>
                                        </div>

                                        <button type="button" onClick={() => nextStep()} className="btn btn-primary">Få offert <Icon.ChevronRight /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </section>
        </Layout>
    );
};

export default SolcellsKalkylator;
